import { graphql } from 'gatsby';
import React from 'react';
import PageMetaData from '../components/layout/PageMetaData';
import Clients from '../components/sections/clients/Clients';
import Hero from '../components/sections/Hero';
import MainSlider from '../components/sections/main-slider/MainSlider';
import Partners from '../components/sections/partners/Partners';
import Services from '../components/sections/services/Services';
import Solutions from '../components/sections/solutions/Solutions';
import LayoutDefault from '../layouts/LayoutDefault';
import { AllJsonData } from '../types/graphql';
import { Partner } from '../types/partner';
import { Service } from '../types/service';
import { Slide } from '../types/slide';
import { Solution } from '../types/solution';

interface HomeProps
  extends AllJsonData<
    Service | Slide | Solution | Partner,
    { services: []; mainSlides: []; solutions: []; partners: [] }
  > {}

const Home: React.FC<HomeProps> = ({ data }) => {
  const services = data?.services.nodes || [];
  const slides = data?.mainSlides.nodes || [];
  const solutions = data?.solutions.nodes || [];
  const partners = data?.partners.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="Homepage" />
      <MainSlider slides={slides as Slide[]} />
      <Hero className="illustration-section-01" />
      <Services
        topDivider
        services={services as Service[]}
        title="Services we provide"
        bottomDescription="Looking for a complete solution for your business needs? Contact us and lets discuss that!"
        isInnerSection
        isSlider
      />
      <Solutions topDivider solutions={solutions as Solution[]} title="Solutions we offer" isInnerSection isSlider />
      <Clients topDivider title="Our Customers" />
      <Partners
        topDivider
        partners={partners as Partner[]}
        title="Our Partners"
        invertMobile
        imageFill
        className="illustration-section-02"
      />
    </LayoutDefault>
  );
};

export default Home;

export const query = graphql`
  query HomePage {
    services: allJson(filter: { contentName: { eq: "services" } }) {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
      }
    }
    mainSlides: allJson(filter: { contentName: { eq: "main-slider" } }, sort: { fields: order }) {
      nodes {
        title
        logo {
          publicURL
        }
        background {
          publicURL
        }
        description
        backgroundColor
        textColor
        image {
          publicURL
        }
        buttonTitle
        buttonUrl
        buttonStyle
        isExternalUrl
        descriptionWidth
        icons {
          alt
          src {
            publicURL
          }
        }
        paginationColor
      }
    }
    solutions: allJson(filter: { contentName: { eq: "solutions" } }, sort: { fields: order }) {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
      }
    }
    partners: allJson(filter: { contentName: { eq: "partners" } }, sort: { fields: order }) {
      nodes {
        title
        description
        image {
          publicURL
        }
        url
      }
    }
  }
`;
