import React from 'react';
import Image from '../../../elements/Image';
import { Service } from '../../../../types/service';
import classNames from 'classnames';

interface ServiceCardProps {
  className?: string;
  service: Service;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ className, service }) => (
  <div className={classNames(className, 'tiles-item')}>
    <div className="tiles-item-inner bg-primary-dark">
      <div className="features-tiles-item-header center-content">
        <div className="features-tiles-item-image mb-16">
          <Image src={service.image.publicURL} alt="" width={64} height={64} />
        </div>
        <h4 className="mt-0 mb-8">{service.title}</h4>
      </div>
      <div className="features-tiles-item-content text-color-mid center-content">
        <p className="m-0 text-sm">{service.description}</p>
      </div>
    </div>
  </div>
);

export default ServiceCard;
