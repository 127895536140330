import React from 'react';
import classNames from 'classnames';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import SectionHeader, { SectionHeaderTag } from '../partials/SectionHeader';
import { Solution } from '../../../types/solution';
import { AllJsonData } from '../../../types/graphql';
import SolutionCard from './partials/SolutionCard';
import Button from '../../elements/Button';

interface SolutionsProps extends SectionTilesTypesProps, AllJsonData<Solution, { solution: [] }> {
  className?: string;
  solutions: Solution[];
  title: string;
  subtitle?: string;
  description?: string;
  isInnerSection?: boolean;
  isSlider?: boolean;
}

const defaultProps: Partial<SolutionsProps> = {
  solutions: [],
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
  isInnerSection: false,
  isSlider: false,
};

const Solutions: React.FC<SolutionsProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  solutions,
  title,
  subtitle,
  description,
  isInnerSection,
  isSlider,
  ...props
}) => {
  const outerClasses = classNames(
    'solutions section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap tiles-full-width',
    isSlider && 'tiles-spaceless',
    pushLeft && 'push-left',
  );

  const swiperOptions: Swiper = {
    modules: [Pagination, Autoplay],
    pagination: { clickable: true, el: '.swiper-pagination' },
    slidesPerView: 1,
    spaceBetween: 24,
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
  };

  return (
    <section id="solutions-section" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            tag={isInnerSection ? SectionHeaderTag.H2 : SectionHeaderTag.H1}
            title={title}
            className="center-content"
          >
            {subtitle && <h2 className="h4">{subtitle}</h2>}
            {description && <p className="mb-0">{description}</p>}
          </SectionHeader>
          <div className={tilesClasses}>
            {isSlider ? (
              <Swiper {...swiperOptions} className="swiper-container pb-32">
                <div className="swiper-wrapper">
                  {solutions?.map((solution, i) => (
                    <SwiperSlide key={i} className="swiper-slide">
                      <SolutionCard solution={solution} key={i} className="h-100" />
                    </SwiperSlide>
                  ))}
                </div>
                <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal mb-n16" />
              </Swiper>
            ) : (
              solutions?.map((solution, i) => (
                <SolutionCard solution={solution} key={i} className="reveal-from-bottom" />
              ))
            )}
          </div>
          <div className="center-content">
            <div className="spacer-64 spacer-32-mobile" />
            <div className="container-xs reveal-from-bottom">
              <p className="text-white">
                Interested in any of above solutions or have another idea that needs realization? Contact us right away!{' '}
              </p>
              <Button
                tag="a"
                color="primary"
                wideMobile
                target="_blank"
                href="https://calendly.com/teambit-atlassian/demo"
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Solutions.defaultProps = defaultProps;

export default Solutions;
