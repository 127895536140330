import React from 'react';
import { Solution } from '../../../../types/solution';
import classNames from 'classnames';

interface SolutionCardProps {
  className?: string;
  solution: Solution;
}

const SolutionCard: React.FC<SolutionCardProps> = ({ className, solution }) => (
  <div className={classNames(className, 'tiles-item')}>
    <div className="tiles-item-inner bg-primary-dark">
      <div className="features-tiles-item-header center-content">
        <h4 className="mt-0">{solution.title}</h4>
      </div>
      <div className="features-tiles-item-content center-content">
        <p className="m-0 text-sm">{solution.description}</p>
      </div>
    </div>
  </div>
);

export default SolutionCard;
