import React, { useState } from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { SectionTilesTypesProps } from '../../../utils/SectionProps';
import { Slide } from '../../../types/slide';
import SwiperSlideContent from '../../elements/SwiperSlideContent';

interface MainSliderProps extends SectionTilesTypesProps {
  className?: string;
  slides: Slide[];
}

const defaultProps: MainSliderProps = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
  slides: [],
};

const MainSlider: React.FC<MainSliderProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  slides,
  ...props
}) => {
  const [paginationColor, setPaginationColor] = useState<string>('white');

  const outerClasses = classNames(
    'main-slider section',
    className,
  );

  const innerClasses = classNames(
    'main-slider-inner',
  );

  const swiperOptions: Swiper = {
    modules: [Pagination, Autoplay],
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: { clickable: true, el: '.swiper-pagination' },
    slidesPerView: 1,
    onSlideChange: swiper => setPaginationColor(slides[swiper.activeIndex].paginationColor || ''),
  };

  const getSlideBackgroundStyle = (slide: Slide) => {
    const slideStyle: { backgroundColor?: string; backgroundImage?: string } = {};

    if (slide.backgroundColor) {
      slideStyle.backgroundColor = slide.backgroundColor;
    }

    if (slide.background) {
      slideStyle.backgroundImage = `url(${slide.background.publicURL})`;
    }

    return slideStyle;
  };

  return (
    <section {...props} className={outerClasses}>
      <div>
        <div className={innerClasses}>
          <Swiper {...swiperOptions} className="swiper-container swiper-fullscreen">
            <div className="swiper-wrapper">
              {slides?.map((slide, i) => (
                <SwiperSlide
                  key={i}
                  className="swiper-slide slide-black d-md-flex align-items-center"
                  style={getSlideBackgroundStyle(slide)}
                >
                  <SwiperSlideContent slide={slide} />
                </SwiperSlide>
              ))}
            </div>
            <div
              className={`swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal ${
                paginationColor !== 'white' ? 'swiper-pagination-dark' : ''
              }`}
            />
          </Swiper>
        </div>
      </div>
    </section>
  );
};

MainSlider.defaultProps = defaultProps;

export default MainSlider;
