import React from 'react';
import { Row, Col } from 'antd';
import { Slide } from '../../types/slide';
import Image from './Image';
import Button from './Button';

interface SwiperSlideContentProps {
  slide: Slide;
}

const SwiperSlideContent: React.FC<SwiperSlideContentProps> = ({ slide }) => (
  <>
    <div className="container container-md-center slide-content">
      <Row>
        <Col
          span={24}
          md={{ span: slide.descriptionWidth === 'wide' ? 16 : 14 }}
          lg={{ span: slide.descriptionWidth === 'wide' ? 14 : 10 }}
        >
          <div>
            {slide.logo && <Image src={slide.logo.publicURL} alt={slide.title} className="img-fluid mb-4" />}
            {slide.icons && (
              <div>
                {slide.icons.map((icon, index) => (
                  <div className={`d-inline-block center-content pr-8`} key={index}>
                    <Image src={icon.src.publicURL} alt={icon.alt} className="mx-auto" />
                    <h5 className={`font-weight-bold mb-8 slide-icon-description text-${slide.textColor}`}>
                      {icon.alt}
                    </h5>
                  </div>
                ))}
              </div>
            )}
          </div>
          <h2 className={`slide-content-title mt-0 text-${slide.textColor}`}>{slide.description}</h2>

          {slide.buttonTitle && slide.buttonUrl && slide.isExternalUrl && (
            <div className="mt-4">
              <Button tag="a" color={slide.buttonStyle} wideMobile href={slide.buttonUrl} target="_blank">
                {slide.buttonTitle}
              </Button>
            </div>
          )}

          {slide.buttonTitle && slide.buttonUrl && !slide.isExternalUrl && (
            <div className="mt-4">
              <Button tag="a" color={slide.buttonStyle} wideMobile href={slide.buttonUrl}>
                {slide.buttonTitle}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
    <div className="container-fluid w-100 align-self-center">
      <Row>
        <Col
          span={24}
          md={{
            span: slide.descriptionWidth === 'wide' ? 12 : 10,
            offset: slide.descriptionWidth === 'wide' ? 12 : 14,
          }}
          lg={{
            span: slide.descriptionWidth === 'wide' ? 14 : 12,
            offset: slide.descriptionWidth === 'wide' ? 10 : 12,
          }}
        >
          <Image
            src={slide.image?.publicURL}
            className="swiper-lazy slide-content-image img-fluid d-block mx-auto"
            alt=""
          />
        </Col>
      </Row>
    </div>
  </>
);

export default SwiperSlideContent;
