import React from 'react';
import classNames from 'classnames';
import { SectionProps, SectionSharedTypesProps } from '../../utils/SectionProps';
import LogoPlatinumSolutionPartner from '../layout/partials/LogoPlatinumSolutionPartner';

interface HeroProps extends SectionSharedTypesProps {
  className?: string;
}

const defaultProps: HeroProps = {
  ...SectionProps.defaults,
};

const Hero: React.FC<HeroProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <LogoPlatinumSolutionPartner width={280} height={90} className="mb-24" />
            <p className="mb-0">
              TeamBit specializes in harnessing the potential of Atlassian technologies.
              With a comprehensive proficiency spanning the Atlassian ecosystem, we deliver an extensive array of services
              and solutions designed to fuel your business expansion.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.defaultProps = defaultProps;

export default Hero;
